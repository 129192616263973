<template>
    <div class="sims-landing">
        <a-spin size="large" />
    </div>
</template>

<script>
import router from '../../router';
import onboarding from '../../api/onboarding';
import { mapActions, mapGetters } from 'vuex';
import posthog from 'posthog-js';

const MAPPINGS = [
    // Marketing
    {
        "guid": "9c10637c-3bb7-4bc0-afa8-72c6889ed191",
        "menuItem": "*NEW* teamSOS",
        "description": "Marketing - Focus",
        "type": "marketing",
        go: () => {
            router.push({
                path: '/guest-onboarding',
                query: router.currentRoute.query
            });
        }
    },
    {
        "guid": "9c10637c-3bb7-4bc0-afa8-72c6889ed191",
        "menuItem": "TeamSOS",
        "description": "Marketing - Focus",
        "type": "marketing",
        go: () => {
            router.push({
                path: '/guest-onboarding',
                query: router.currentRoute.query
            });
        }
    },
    {
        "guid": "0743ab6e-95a5-4401-8d85-a2cc549374c7",
        "menuItem": "What is teamSOS?",
        "description": "Marketing - Pupil - What Is...?",
        "type": "marketing",
        go: () => {
            router.push({
                path: '/guest-onboarding',
                query: router.currentRoute.query
            });
        }
    },
    {
        "guid": "0743ab6e-95a5-4401-8d85-a2cc549374c7",
        "menuItem": "Success stories",
        "description": "Marketing - Pupil - Success Stories",
        "type": "marketing",
        go: () => {
            let query = router.currentRoute.query;
            query.section = 'success-stories';
            router.push({
                path: '/guest-onboarding',
                query: query
            });
        }
    },
    {
        "guid": "6eae9184-01b5-4acf-8d70-83e59c62e022",
        "menuItem": "What is teamSOS?",
        "description": "Marketing - Staff - What Is...?",
        "type": "marketing",
        go: () => {
            router.push({
                path: '/guest-onboarding',
                query: router.currentRoute.query
            });
        }
    },
    {
        "guid": "6eae9184-01b5-4acf-8d70-83e59c62e022",
        "menuItem": "Success stories",
        "description": "Marketing - Staff - Success Stories",
        "type": "marketing",
        go: () => {
            let query = router.currentRoute.query;
            query.section = 'success-stories';
            router.push({
                path: '/guest-onboarding',
                query: query
            });
        }
    },
    {
        "guid": "f0e7e581-2c0f-4aa5-ba26-80e0dfd31169",
        "menuItem": "What is teamSOS?",
        "description": "Marketing - Take Register - What Is...?",
        "type": "marketing",
        go: () => {
            router.push({
                path: '/guest-onboarding',
                query: router.currentRoute.query
            });
        }
    },
    {
        "guid": "f0e7e581-2c0f-4aa5-ba26-80e0dfd31169",
        "menuItem": "Success stories",
        "description": "Marketing - Take Register - Success Stories",
        "type": "marketing",
        go: () => {
            let query = router.currentRoute.query;
            query.section = 'success-stories';
            router.push({
                path: '/guest-onboarding',
                query: query
            });
        }
    },

    // Live
    {
        "guid": "99a669d9-5f0e-4043-ab09-1a10b9671084",
        "menuItem": "teamSOS",
        "description": "Live - Focus",
        "type": "live",
        go: () => {
            let params = router.currentRoute.query;
            params['intent'] = '/dashboard';
            router.push({
                path: '/sims-login',
                query: params
            });
        }
    },
    {
        "guid": "d7a6164c-1a1a-4907-a8d5-3c6bc05ab7d6",
        "menuItem": "Call for help",
        "description": "Live - Pupil - Call For Help",
        "type": "live",
        go: () => {
            let params = router.currentRoute.query;
            params['intent'] = '/call-for-help';
            router.push({
                path: '/sims-login',
                query: params
            });
        }
    },
    {
        "guid": "d7a6164c-1a1a-4907-a8d5-3c6bc05ab7d6",
        "menuItem": "Active Incidents",
        "description": "Live - Pupil - Active Incidents",
        "type": "live",
        go: () => {
            let params = router.currentRoute.query;
            params['intent'] = '/incidents';
            router.push({
                path: '/sims-login',
                query: params
            });
        }
    },
    {
        "guid": "d7a6164c-1a1a-4907-a8d5-3c6bc05ab7d6",
        "menuItem": "Chat",
        "description": "Live - Pupil - Chat",
        "type": "live",
        go: () => {
            let params = router.currentRoute.query;
            params['intent'] = '/chats';
            router.push({
                path: '/sims-login',
                query: params
            });
        }
    },
    {
        "guid": "d7a6164c-1a1a-4907-a8d5-3c6bc05ab7d6",
        "menuItem": "Guidance",
        "description": "Live - Pupil - Guidance",
        "type": "live",
        go: () => {
            let params = router.currentRoute.query;
            params['intent'] = '/guidance';
            router.push({
                path: '/sims-login',
                query: params
            });
        }
    },
    {
        "guid": "e85185ce-ebf5-440a-9235-94af6766d792",
        "menuItem": "Call for help",
        "description": "Live - Staff - Call For Help",
        "type": "live",
        go: () => {
            let params = router.currentRoute.query;
            params['intent'] = '/call-for-help';
            router.push({
                path: '/sims-login',
                query: params
            });
        }
    },
    {
        "guid": "e85185ce-ebf5-440a-9235-94af6766d792",
        "menuItem": "Active Incidents",
        "description": "Live - Staff - Active Incidents",
        "type": "live",
        go: () => {
            let params = router.currentRoute.query;
            params['intent'] = '/incidents';
            router.push({
                path: '/sims-login',
                query: params
            });
        }
    },
    {
        "guid": "e85185ce-ebf5-440a-9235-94af6766d792",
        "menuItem": "Chat",
        "description": "Live - Staff - Chat",
        "type": "live",
        go: () => {
            let params = router.currentRoute.query;
            params['intent'] = '/chats';
            router.push({
                path: '/sims-login',
                query: params
            });
        }
    },
    {
        "guid": "e85185ce-ebf5-440a-9235-94af6766d792",
        "menuItem": "Guidance",
        "description": "Live - Staff - Guidance",
        "type": "live",
        go: () => {
            let params = router.currentRoute.query;
            params['intent'] = '/guidance';
            router.push({
                path: '/sims-login',
                query: params
            });
        }
    },
    {
        "guid": "e456a8d8-7cfa-46b4-9f72-03cc7df89da6",
        "menuItem": "Call for help",
        "description": "Live - Attendance - Call For Help",
        "type": "live",
        go: () => {
            let params = router.currentRoute.query;
            params['intent'] = '/call-for-help';
            router.push({
                path: '/sims-login',
                query: params
            });
        }
    },
    {
        "guid": "e456a8d8-7cfa-46b4-9f72-03cc7df89da6",
        "menuItem": "Active Incidents",
        "description": "Live - Attendance - Active Incidents",
        "type": "live",
        go: () => {
            let params = router.currentRoute.query;
            params['intent'] = '/incidents';
            router.push({
                path: '/sims-login',
                query: params
            });
        }
    },
    {
        "guid": "e456a8d8-7cfa-46b4-9f72-03cc7df89da6",
        "menuItem": "Chat",
        "description": "Live - Attendance - Chat",
        "type": "live",
        go: () => {
            let params = router.currentRoute.query;
            params['intent'] = '/chats';
            router.push({
                path: '/sims-login',
                query: params
            });
        }
    },
    {
        "guid": "e456a8d8-7cfa-46b4-9f72-03cc7df89da6",
        "menuItem": "Guidance",
        "description": "Live - Attendance - Guidance",
        "type": "live",
        go: () => {
            let params = router.currentRoute.query;
            params['intent'] = '/guidance';
            router.push({
                path: '/sims-login',
                query: params
            });
        }
    }
];

export default {
    computed: {
        ...mapGetters('guestOnboarding', {
            externalIdProvider: 'externalIdProvider',
            externalId: 'externalId'
        }),

        menuItem() {
            return this.$route.query.MenuItem;
        },

        partnerLinkUniqueID() {
            return this.$route.query.PartnerLinkUniqueID;
        },

        userExternalId() {
            return this.$route.query.SIMSUserExternalID;
        },

        laNumber() {
            return this.$route.query.LANumber;
        },

        schoolNumber() {
            return this.$route.query.SchoolNumber;
        }
    },

    methods: {
        ...mapActions('guestOnboarding', {
            setExternalIdProvider: 'setExternalIdProvider',
            setExternalId: 'setExternalId'
        })
    },

    created() {
        // If posthog initialised
        if (window.POSTHOG_INITIALISED) {
            posthog.capture('sims_button_clicked');
        }

        // If has local authority number, save to external id
        if (this.laNumber && this.schoolNumber) {
            this.setExternalIdProvider('LEADFES');
            this.setExternalId(this.laNumber + '-' + this.schoolNumber);
        }

        if (this.externalIdProvider && this.externalId) {
            onboarding.doesOrganisationWithExternalProviderIdExist(
                this.externalIdProvider,
                this.externalId
            ).then(() => {

                // const mapping = MAPPINGS.find(mapping => mapping.guid === this.partnerLinkUniqueID && mapping.menuItem === this.menuItem);
                const mapping = MAPPINGS.find(mapping => mapping.guid === this.partnerLinkUniqueID && mapping.menuItem === this.menuItem);

                if (mapping) {
                    // If the org has already signed up
                    // And we're trying to go to a marketing page
                    // Then lets go to sims login page
                    if (mapping.type == 'marketing') {
                        router.push({
                            path: '/sims-login',
                            query: router.currentRoute.query
                        });
                    }
                    // Otherwise, go to the page we're trying to go to
                    else {
                        mapping.go();
                    }
                } else {
                    return;
                }

            }).catch(e => {

                if (e.response.status === 404) {
                    const mapping = MAPPINGS.find(mapping => mapping.guid === this.partnerLinkUniqueID && mapping.menuItem === this.menuItem);

                    if (mapping) {

                        // If the org has not signed up
                        // And we're trying to go to a live page
                        // Let's go to the guest onboarding page
                        if (mapping.type == 'live') {
                            router.push({
                                path: '/guest-onboarding',
                                query: router.currentRoute.query
                            });
                        }
                        // Otherwise, go to the page we're trying to go to
                        else {
                            mapping.go();
                        }
                    } else {
                        return;
                    }
                }

            });
        }
    }
}
</script>

<style scoped lang="scss">
.sims-landing {
    background: rgb(58, 11, 163);
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    flex-direction: column;
}
</style>